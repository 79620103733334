.main_container{
	display: flex;
	border: #F7F8FA 1px solid;
}

.left_container{
	background: #fff;
	width: 50%;
}

.left_container_inner{
	max-width: 570px;
	float: right;
	padding-bottom: 196px;
	padding-top: 169px;
	padding-right: 70px;
}

.right_container{
	background: #f7f8fa ;
	width: 50%;
}

.right_container_inner{
	max-width: 570px;
	float: left;
	padding-bottom: 196px;
	padding-top: 169px;
	padding-left: 70px;
}

.logo{
	max-width: 234px;
}

.title_h1{
	color: #444444;
	font-size: 48px;
	line-height: 58px;
	margin-top: 31px;

}

.title_h1 span{
	display: block;
}

.p_text{
	color: #828282;
	font-size: 16px;
	line-height: 28px;
	margin-top: 30px;
}

.donate_btn{
	background: #A0CE4E;
	width: 205px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 14px;
	font-family: 'Poppins-bold';
	margin-top: 30px;
	text-transform: uppercase;
	letter-spacing: 0.56px;
	text-decoration: none;
}

.p_slam{
	color: #828282;
	font-size: 14px;
	line-height: 28px;
	margin-top: 20px;
	letter-spacing: 0;
}

.p_slam span{
	display: block;
}

.footer {
    border-top: #E8E9E9 1px solid;
    padding-top: 13px;
    margin-top: 60px;
    display: flex;
    align-items: center;
}

.footer span{
	font-size:12px;
	color: #828282;
	font-family: 'Poppins-Regular';
	margin-right: 5px;
}

.dcLogo{
	width: 96px;
}

.dcLogoLarge{
	width: 190px;
	margin: 0 auto;
}

.logo_deenconnect{
	text-align: center;
}

.MuiExpansionPanel-root.Mui-expanded:first-child {
    margin-top: 0;
    background: none;
}

.MuiExpansionPanel-root {
	background: none !important;
	border-top: #0000000A 1px solid;
	position: initial !important;
}

.accordion_container{
	margin-top: 20px;
}

.MuiExpansionPanelSummary-content {
    margin: 16px 0 !important;
    display: flex;
    flex-grow: 1;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.accordion_container .MuiSvgIcon-root{
	opacity:0.4;
}

.MuiExpansionPanelDetails-root {
    display: flex;
    padding: 0 !important;
}

.MuiExpansionPanelSummary-root {
    padding: 0px 4px !important;
}

.accTitle{
	font-size: 14px !important;
	color: #444444 !important;
	font-family: 'Poppins-SemiBold' !important;
	letter-spacing: 0 !important;
	text-transform: uppercase;
}

.accDate{
	font-size:14px  !important;
	color: #828282 !important;
	font-family: 'Poppins-Regular' !important;
	letter-spacing: 0;
	line-height: 28px !important;
}

.mobile_view{
	display: none;
}

.MuiExpansionPanelSummary-root {
    padding: 0 0px !important;
}

.MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: 48px !important;
}

@media(max-width: 1370px){

	.left_container_inner{
		padding-left:70px;
	}
	
	
	.right_container_inner{
		padding-right:70px;
	}
}

@media(max-width: 850px){

	.main_container{
		display: block;
		border: none;
		margin: 0;
	}

	.left_container{
		background: #fff;
		min-height: initial;
		width: auto;
	}
	
	.right_container{
		background: #f7f8fa ;
		min-height: initial;
		width: auto;
	}

	.left_container_inner {
		padding: 24px;
		float: none;
	}

	.right_container_inner{
		padding: 24px;
		float: none;
	}

	.title_h1 {
		color: #444444;
		font-size: 30px;
		line-height: 40px;
		margin-top: 20px;
	}

	.p_text {
		color: #828282;
		font-size: 16px;
		line-height: 28px;
		margin-top: 20px;
	}

	.logo {
		max-width: 230px;
	}

	.dekstop_view{
		display: none;
	}

	.mobile_view{
		display: block;
	}
}

